export const FOOD_MENU = {
  main: [
    {
      name: 'Салаты',
      dishes: [
        {
          name: 'Салат из запеченых овощей "ХАРАВАЦ" 260г',
          price: '450р.'
        },
        {
          name: 'Летний салат с баклажанами и брынзой 260г',
          price: '490р.'
        },
        {
          name: 'Руккола со слабоселеным лососем 240г',
          price: '550р.'
        },
        {
          name: 'Теплый салат с курицей и сладким перцем 260г',
          price: '500р.'
        },
        {
          name: 'Микс салат с копченой говядиной 270г',
          price: '500р.'
        },
        {
          name: 'Микс салат с пармской ветчиной 250г',
          price: '590р.'
        },
        {
          name: 'Рваная моцарелла 250г',
          price: '450р.'
        },
        {
          name: 'Тёплый салат с куриной печенью, микс салатом, дольками апельсина и кедровыми орешками 250г',
          price: '490р.'
        },
        {
          name: 'Салат с морепродуктами, салатом романо и соусом терияки 270г',
          price: '720р.'
        },
        {
          name: 'Теплый салат с охотничьими  колбасками 270г',
          price: '530р.'
        },
        {
          name: 'Овощной салат со сливочным сыром 260г',
          price: '490р.'
        },
        {
          name: 'Цезарь с курицей 220г',
          price: '490р.'
        },
        {
          name: 'Цезарь с креветками 220г',
          price: '590р.'
        },
        {
          name: 'Баварский салат с говядиной и сыром 310г',
          price: '530р.'
        },
        {
          name: 'Салат из баклажанов в азиатском стиле 250г',
          price: '480р.'
        },
      ]
    },
    {
      name: 'Холодные закуски',
      dishes: [
        {
          name: 'Рулеты из баклажанов с орехами кешью 250г',
          price: '480р.'
        },
        {
          name: 'Рулетики из бекона с сыром и яйцами 280г',
          price: '500р.'
        },
        {
          name: 'Чиабатта  с авокадо и семгой 320г',
          price: '620р.'
        },
        {
          name: 'Тартар из лосося с гренками и перепелиным яйцом 220г',
          price: '850р.'
        },
        {
          name: 'Плато к пиву 200г/30г',
          price: '650р.'
        },
        {
          name: 'Соленья ассорти 200г',
          price: '500р.'
        },
        {
          name: 'Овощное ассорти 300г',
          price: '400р.'
        },
        {
          name: 'Сельдь с отварным картофелем и гренками 150г',
          price: '400р.'
        },
        {
          name: 'Мясное, Рыбное, Сырное плато 220г',
          price: '700р.'
        },
      ]
    },
    {
      name: 'Горячие закуски',
      dishes: [
        {
          name: 'Кесадилья с курицей и сыром 250г',
          price: '480р.'
        },
        {
          name: 'Хрустящие куриные палочки 5шт',
          price: '400р.'
        },
        {
          name: 'Хрустящие креветки "Васаби" 5шт',
          price: '440р.'
        },
        {
          name: 'Свиные ребрышки в соусе барбекю 250г',
          price: '490р.'
        },
        {
          name: 'Грибной жульен 180г',
          price: '390р.'
        },
        {
          name: 'Гренки чесночные с сырным соусом 190г',
          price: '220р.'
        },
        {
          name: 'Куриные крылья BBQ с соусом "Блючиз 250г/50г',
          price: '420р.'
        },
        {
          name: 'Пивные креветки 300г',
          price: '700р.'
        },
        {
          name: 'Сырные шарики с соусом тартар 160г/30г',
          price: '360р.'
        },
        {
          name: 'Кольца кальмара в кляре 160г/30г',
          price: '400р.'
        },
      ]
    },
    {
      name: 'Супы',
      dishes: [
        {
          name: 'Окрошка на кефире/квасе 300г',
          price: '300р.'
        },
        {
          name: 'Уйгурский лагман 350г',
          price: '400р.'
        },
        {
          name: 'Солянка мясная сборная 350г',
          price: '380р.'
        },
        {
          name: 'Говяжий суп "Харчо" 300г',
          price: '350р.'
        },
        {
          name: 'Пельмени домашние 300г',
          price: '400р.'
        },
        {
          name: 'Гороховый суп с копченостями 300г',
          price: '350р.'
        },
        {
          name: 'Уха из лосося и судака 350г',
          price: '400р.'
        },
        {
          name: 'Борщ с пампушками и салом 300г',
          price: '350р.'
        },
        {
          name: 'Тыквенный крем-суп 300г',
          price: '350р.'
        },
        {
          name: 'Грибной крем-суп 300г',
          price: '350р.'
        },
        {
          name: 'Тайский острый суп с морепродуктами Том-ям 350г',
          price: '500р.'
        },
      ]
    },
    {
      name: 'Вок (лапша/рис)',
      dishes: [
        {
          name: 'Креветки',
          price: '500р.'
        },
        {
          name: 'Курица',
          price: '500р.'
        },
        {
          name: 'Говядина',
          price: '500р.'
        },
        {
          name: 'Овощи',
          price: '400р.'
        },
      ]
    },
    {
      name: 'Горячее MEAT',
      dishes: [
        {
          name: 'Мититеи по Молдавски 280г',
          price: '520р.'
        },
        {
          name: 'Котлета по Киевски 250г/150г',
          price: '550р.'
        },
        {
          name: 'Стейк из индейки с овощами на гриле 250г/150г',
          price: '700р.'
        },
        {
          name: 'Говядина тушеная с картофешльным пюре 350г',
          price: '650р.'
        },
        {
          name: 'Мини цыпленок ТАБАКА 350г/150г/30г',
          price: '700р.'
        },
        {
          name: 'Скоблянка из трех видов мяса 420г',
          price: '700р.'
        },
        {
          name: 'Стейк из телятины с пряным соусом 300г',
          price: '950р.'
        },
        {
          name: 'Бургер "ШЕФ"с картофелем фри 360г/150г',
          price: '590р.'
        },
        {
          name: 'Цыпленок терияки со свежими овощами, соусом и сливочным сыром в лаваше 320г',
          price: '450р.'
        },
        {
          name: 'Стейк из свинины на гриле с картофелем черри и красным соусом 250г/150г',
          price: '650р.'
        },
        {
          name: 'Бефстроганов из говядины с картофельным пюре 150г/150г',
          price: '590р.'
        },
        {
          name: 'Куриные котлеты с картофельным пюре 150г/150г',
          price: '500р.'
        },
      ]
    },
    {
      name: 'Мангал',
      dishes: [
        {
          name: 'Овощи на мангале (Помидор,Баклажаны,Бл.Перец) 200г',
          price: '300р.'
        },
        {
          name: 'Шашлык "Наполеон" с салатом Коул Слоу 250г/50г',
          price: '600р.'
        },
        {
          name: 'Шашлык из телятины с салатом Коул Слоу 250г/50г',
          price: '700р.'
        },
        {
          name: 'Люля-кебаб из курицы с салатом Коул Слоу 250г/50г',
          price: '550р.'
        },
        {
          name: 'Шашлык  из свинины с салатом Коул Слоу 250г/50г',
          price: '600р.'
        },
        {
          name: 'Шашлык  из курицы с салатом Коул Слоу 250г/50гр',
          price: '550р.'
        },
        {
          name: 'Шашлык из баранины с салатом Коул Слоу 250г/50г',
          price: '650р.'
        },
        {
          name: 'Люля-кебаб из двух видов мяса салатом Коул Слоу 250г/50г',
          price: '600р.'
        },
        {
          name: 'Каре ягненка на мангале 300г',
          price: '750р.'
        },

      ]
    },
    {
      name: 'Горячее FISH',
      dishes: [
        {
          name: 'Рулетики из Скумрии и овощами 280г',
          price: '450р.'
        },
        {
          name: 'Мидии в белом вине и сыром горгонзола 300г',
          price: '700р.'
        },
        {
          name: 'Ассорти из морепродуктов на гриле 300г',
          price: '800р.'
        },
        {
          name: 'Филе судака под сырной шапкой 250г',
          price: '580р.'
        },
        {
          name: 'Запеканка из морепродуктов с сыром 430г',
          price: '700р.'
        },
        {
          name: 'Мидии под сырным соусом 250г',
          price: '550р.'
        },
        {
          name: 'Стейк из лосося на гриле с цветной капустой 150г/150г',
          price: '850р.'
        },
        {
          name: 'Сибас гриль 100г',
          price: '250р.'
        },
        {
          name: 'Дорадо гриль 100г',
          price: '250р.'
        },
      ]
    },
    {
      name: 'Гарниры',
      dishes: [
        {
          name: 'Рис с овощами 150г',
          price: '300р.'
        },
        {
          name: 'Картофель (пюре, черри, фри) 150г',
          price: '250р.'
        },
        {
          name: 'Овощи (гриль/на пару) 150г',
          price: '300р.'
        },
      ]
    },
    {
      name: 'Паста',
      dishes: [
        {
          name: 'Паста Болоньезе 300г',
          price: '550р.'
        },
        {
          name: 'Тальятелле с креветками 300г',
          price: '600р.'
        },
        {
          name: 'Фетучини с лососем в сливочном соусе 300г',
          price: '650р.'
        },
        {
          name: 'Спагетти Карбонара с беконом 300г',
          price: '580р.'
        },
        {
          name: 'Фетучини с курицей и грибами 300г',
          price: '580р.'
        },
      ]
    },
    {
      name: 'Пицца (32см)',
      dishes: [
        {
          name: 'Пицца с Ветчиной и грибами',
          price: '650р.'
        },
        {
          name: 'Пицца с соусом цезарь (моцарелла, куриное филе, соус Цезарь, листья салата, пармезан, томаты)',
          price: '650р.'
        },
        {
          name: 'Маргарита (томатный соус ,моцарелла, томаты, базилик)',
          price: '500р.'
        },
        {
          name: 'Цыпленок терияки (курица ,соус терияки, болг.перец, лук, моцарелла, пармезан ,стружка тунца)',
          price: '650р.'
        },
        {
          name: 'Пепперони (моцарелла, пепперони, пармезан)',
          price: '590р.'
        },
        {
          name: 'Четыре сыра (дорблю, пармезан, моцарелла, гауда)',
          price: '700р.'
        },
        {
          name: 'Европейская (говядина, бекон, пепперони, грибы, перец.болг, томаты, лук, пармезан, моцарелла, табаско)',
          price: '750р.'
        },
        {
          name: 'Дополнительный ингредиент к пицце 30г',
          price: '100р.'
        },
      ]
    },
    {
      name: 'Роллы и суши',
      dishes: [
        {
          name: 'Запеченный ролл с угрем 180г',
          price: '490р.'
        },
        {
          name: 'Филадельфия с креветкой 180г',
          price: '550р.'
        },
        {
          name: 'Филадельфия с лососем 180г',
          price: '550р.'
        },
        {
          name: 'Хрустящий ролл с угрем 180г',
          price: '500р.'
        },
        {
          name: 'Спайси Калифорния с креветкой темпура 180г',
          price: '500р.'
        },
        {
          name: 'Канадский ролл с угрем 170г',
          price: '500р.'
        },
        {
          name: 'Теплый  ролл со сливочным сыром 230г',
          price: '550р.'
        },
        {
          name: 'Ролл с авкадо/огурцом 120гр',
          price: '250р.'
        },
      ]
    },
    {
      name: 'Хлеб и выпечка',
      dishes: [
        {
          name: 'Хачапури по аджарски 400г',
          price: '450р.'
        },
        {
          name: 'Лепешка из Тандыра',
          price: '120р.'
        },
        {
          name: 'Лепешка с сыром',
          price: '120р.'
        },
        {
          name: 'Фокачча с песто и сыром',
          price: '200р.'
        },
        {
          name: 'Хлебная корзина (черный, бородинский, белый)',
          price: '100р.'
        },
      ]
    },
    {
      name: 'Десерты',
      dishes: [
        {
          name: 'Сливочно кофейный десерт "Тирамису" 200г',
          price: '300р.'
        },
        {
          name: 'Сливочно бисквитный десерт "Трайфу" 200г',
          price: '350р.'
        },
        {
          name: 'Медово-Шоколдный десерт "Спартак" 150г',
          price: '350р.'
        },
        {
          name: 'Пирожное "Графские развалины" 120г',
          price: '300р.'
        },
        {
          name: 'Торт "Наполеон" 170г',
          price: '350р.'
        },
        {
          name: 'Торт Пьемонт с шоколадной глазурью 180г',
          price: '300р.'
        },
        {
          name: 'Красный бархат 170г',
          price: '300р.'
        },
        {
          name: 'Шоколадный фондан с мороженым 150г',
          price: '320р.'
        },
        {
          name: 'Чизкейк в ассортименте 120г',
          price: '300р.'
        },
        {
          name: 'Штрудель яблоко/вишня (подается с шариком ванильного мороженого) 150г/30г',
          price: '350р.'
        },
        {
          name: 'Сорбет в ассортименте 1шт',
          price: '100р.'
        },
        {
          name: 'Мороженное в ассортименте 1шт',
          price: '100р.'
        },
        {
          name: 'Макарон 1шт',
          price: '100р.'
        },

      ]
    },
  ],
  drinks: [
    {
      name: 'Свежевыжатый сок',
      drink: [
        {
          name: 'Морковь 200мл',
          price: '200р.'
        },
        {
          name: 'Яблоко 200мл',
          price: '220р.'
        },
        {
          name: 'Апельсин 200мл',
          price: '300р.'
        },
        {
          name: 'Грейпфрут 200мл',
          price: '300р.'
        },
        {
          name: 'Сельдерей 200мл',
          price: '350р.'
        },
      ]
    },
    {
      name: 'Безалкогольные напитки',
      drink: [
        {
          name: 'Швепс 250мл',
          price: '180р.'
        },
        {
          name: 'Минеральная вода JEVEA 500мл',
          price: '120р.'
        },
        {
          name: 'Кока-кола/Фанта/Спрайт 500мл',
          price: '200р.'
        },
        {
          name: 'Кока-кола в стекле 250мл',
          price: '200р.'
        },
        {
          name: 'Сок/Морс в ассортименте 200мл',
          price: '100р.'
        },
        {
          name: 'Лимонад "Классический Лимонный" 350мл',
          price: '300р.'
        },
        {
          name: 'Лимонад "Лайм-базилик" 350мл',
          price: '300р.'
        },
        {
          name: 'Лимонад "Манго-маракуйя" 350мл',
          price: '350р.'
        },
        {
          name: 'Лимонад  "Огуречный" 350мл',
          price: '260р.'
        },
        {
          name: 'Молочный шейк асс. (кубничный, шоколадный) 300мл',
          price: '280р.'
        },
        {
          name: 'Банановый молочный шейк 300мл',
          price: '300р.'
        },
        {
          name: 'Мохито безалкогольный 200мл',
          price: '300р.'
        },
        {
          name: 'Глинтвейн на вишневом соке 200мл',
          price: '300р.'
        },
      ]
    },
    {
      name: 'Чай листовой ALTHAUS 500мл/1000мл',
      drink: [
        {
          name: 'Английский завтрак черный',
          price: '250р./400р.'
        },
        {
          name: 'Императорский чай',
          price: '250р./400р.'
        },
        {
          name: 'Дикая вишня',
          price: '250р./400р.'
        },
        {
          name: 'Эрл Грей черный с бергамотом',
          price: '300р./400р.'
        },
        {
          name: 'Сенча зеленый',
          price: '300р./400р.'
        },
        {
          name: 'Жасмин Тинг Юань зеленый',
          price: '300р./450р.'
        },
        {
          name: 'Молочный Улун зеленый',
          price: '300р./450р.'
        },
        {
          name: 'Облепиховый с медом',
          price: '400р./550р.'
        },
        {
          name: 'Ягодный микс с мятой',
          price: '400р./550р.'
        },
        {
          name: 'Имбирный с лимоном и медом',
          price: '400р./550р.'
        },
        {
          name: 'Тропический чай с апельсином',
          price: '400р./500р.'
        },
        {
          name: 'Чай "Веранда"',
          price: '350р./500р.'
        },
        {
          name: 'Травяной сбор',
          price: '300р./400р.'
        },
      ]
    },
    {
      name: 'Кофе',
      drink: [
        {
          name: 'Эспрессо 35мл',
          price: '150р.'
        },
        {
          name: 'Допио 60мл',
          price: '200р.'
        },
        {
          name: 'Американо 120мл',
          price: '160р.'
        },
        {
          name: 'Капучино 170мл',
          price: '250р.'
        },
        {
          name: 'Гляссе 150мл кофе и шарик ванильного морожного',
          price: '300р.'
        },
        {
          name: 'Двойной капучино 300мл',
          price: '260р.'
        },
        {
          name: 'Латте 200мл',
          price: '260р.'
        },
        {
          name: 'Раф кофе 200мл',
          price: '270р.'
        },
        {
          name: 'Айс кофе 200мл',
          price: '270р.'
        },
      ]
    },
    {
      name: 'Разливное пиво',
      drink: [
        {
          name: 'Podkovan(Темное) 300мл/500мл',
          price: '250р./300р.'
        },
        {
          name: 'Spaten 300мл/500мл',
          price: '280р./350р.'
        },
        {
          name: 'Stella Artois 300мл/500мл',
          price: '250р./300р.'
        },
        {
          name: 'Brand Beer Veranda 300мл/500мл',
          price: '200р./250р.'
        },
      ]
    },
    {
      name: 'Бутылочное пиво',
      drink: [
        {
          name: 'Эль (пшеничный/вишневый/темный) 750мл',
          price: '450р.'
        },
        {
          name: 'Spaten 500мл',
          price: '300р.'
        },
        {
          name: 'Hoegaarden 500мл',
          price: '300р.'
        },
        {
          name: 'Stella Artois Б/А 440мл',
          price: '300р.'
        },
      ]
    },
    {
      name: 'Шампанское (750 мл)',
      drink: [
        {
          name: 'Bouvet-Ladubay',
          price: '3000р.'
        },
      ]
    },
    {
      name: 'Игристые вина (750 мл)',
      drink: [
        {
          name: 'Prosecco - Brut',
          price: '2250р.'
        },
        {
          name: 'Lambrusco dell\'Emilia Bianco semy sweet',
          price: "1500р."
        },

      ]
    },
    {
      name: 'Белые вина / Новая Зеландия (750 мл)',
      drink: [
        {
          name: 'Sauvignon Blanc',
          price: "2500р."
        },

      ]
    },
    {
      name: 'Белые вина / Италия (750 мл)',
      drink: [
        {
          name: 'Pinot Grigio Cielo',
          price: "2000р."
        },
      ]
    },
    {
      name: 'Белые вина / Германия (750 мл)',
      drink: [
        {
          name: 'Gewurztraminer Hans Baer',
          price: "2000р."
        },
      ]
    },
    {
      name: 'Белые вина / Россия (750 мл)',
      drink: [
        {
          name: 'Поместье Голубицкое Sauvignon Blanc',
          price: "1500р."
        },
      ]
    },

    {
      name: 'Красные вина / Италия (750 мл)',
      drink: [
        {
          name: 'Chianti Serristori',
          price: "2000р.",
        },
      ]
    },
    {
      name: 'Красные вина / Испания (750 мл)',
      drink: [
        {
          name: 'Dos Caprichos Joven',
          price: "2250р.",
        },
      ]
    },
    {
      name: 'Красные вина / Грузия (750 мл)',
      drink: [
        {
          name: 'Kindzmarauli (semi-sweet)',
          price: "1750р.",
        },
      ]
    },
    {
      name: 'Красные вина / Франция (750 мл)',
      drink: [
        {
          name: 'Chevalier d AnThelme',
          price: "2000р.",
        },
      ]
    },
    {
      name: 'Красные вина / Россия (750 мл)',
      drink: [
        {
          name: 'Поместье Голубицкое Merlot',
          price: "1500р.",
        },
      ]
    },
    {
      name: 'Вина по бокалам / белое (150 мл)',
      drink: [
        {
          name: 'Pinot Grigio Cielo',
          price: "400р.",
        },
        {
          name: 'Поместье Голубицкое Sauvignon Blanc',
          price: "300р.",
        },
        {
          name: 'New Zeland Sauvignon Blanc',
          price: "500р.",
        },
        {
          name: 'Gewurztraminer Hans Baer',
          price: "400р.",
        },
      ]
    },
    {
      name: 'Вина по бокалам / красное (150 мл)',
      drink: [
        {
          name: 'Kindzmarauli (semi-sweet)',
          price: "350р.",
        },
        {
          name: 'Chianti Serristori',
          price: "400р.",
        },
        {
          name: 'Поместье Голубицкое Merlot',
          price: "300р.",
        },
        {
          name: 'Dos Caprichos Joven',
          price: "500р.",
        },
        {
          name: 'Chevalier d AnThelme',
          price: "400р.",
        },
      ]
    },

    {
      name: 'Вермут',
      drink: [
        {
          name: 'Martini "Rosso" 100мл',
          price: '300р.',
        },
        {
          name: 'Martini "Exra Dry" 100мл',
          price: '300р.',
        },
        {
          name: 'Martini "Bianco" 100мл',
          price: "300р.",
        },
      ]
    },
    {
      name: 'Водка',
      drink: [
        {
          name: 'Хреновуха 50мл',
          price: "140р.",
        },
        {
          name: 'Beluga 50мл',
          price: "250р.",
        },
        {
          name: 'Tsarskaya 50мл',
          price: "150р.",
        },
        {
          name: 'Кедровуха 50мл',
          price: "140р.",
        },
        {
          name: 'Beluga gold 50мл',
          price: "300р.",
        },
      ]
    },
    {
      name: 'Ром',
      drink: [
        {
          name: 'Bacardi Soperior 50мл',
          price: "300р.",
        },
        {
          name: 'Bacardi Black 50мл',
          price: "300р.",
        },

      ]
    },
    {
      name: 'Джин',
      drink: [
        {
          name: 'Bifiter 50мл',
          price: "300р.",
        },
      ]
    },
    {
      name: 'Текила',
      drink: [
        {
          name: 'Olmeca Blanco/Gold 50мл',
          price: "320р.",
        },
      ]
    },
    {
      name: 'Виски',
      drink: [
        {
          name: 'Chivas Regal 12 50мл',
          price: "500р.",
        },
        {
          name: 'Jemeson 50мл',
          price: "320р.",
        },
        {
          name: 'Red  Label 50мл',
          price: "250р.",
        },
      ]
    },
    {
      name: 'Американский виски / Бурбон',
      drink: [
        {
          name: 'Jack Daniels 50мл',
          price: "350р.",
        },
        {
          name: 'Jim Beam 50мл',
          price: "300р.",
        },
      ]
    },
    {
      name: 'Дижестивы',
      drink: [
        {
          name: 'Jagermeister 50мл',
          price: "300р.",
        },
        {
          name: 'Beherovka 50мл',
          price: "300р.",
        },
        {
          name: 'Grapa 50мл',
          price: "300р.",
        },
        {
          name: 'Абсент 50мл',
          price: "300р.",
        },
        {
          name: 'Самбука 50мл',
          price: "300р.",
        },
      ]
    },
    {
      name: 'Коньяк',
      drink: [
        {
          name: 'Remy Martin VSOP 50мл',
          price: "400р.",
        },
        {
          name: 'НОЙ 5* 50мл',
          price: "250р.",
        },
        {
          name: 'Арарат 5* 50мл',
          price: "300р.",
        },
      ]
    },
    {
      name: 'Коктейли',
      drink: [
        {
          name: 'Martini Royale bianco (Martini bianco/Prosecco) 350мл',
          price: "400р.",
        },
        {
          name: 'Long Island (Vodka/Tequila/Gin/Rom/TripleSec) 350мл',
          price: "550р.",
        },
        {
          name: 'Aperol Spritz (Aperol/Prosecco) 200мл',
          price: "400р.",
        },
        {
          name: 'Mai tai Organic Summer (DryOrange/Rom/PineappleJuise) 350мл',
          price: "400р.",
        },
        {
          name: 'Kir Royal (CremedeCassis/Prosecco) 165мл',
          price: "400р.",
        },
        {
          name: 'Margarita 170мл',
          price: "450р.",
        },
        {
          name: 'Negroni 220мл',
          price: "450р.",
        },
        {
          name: 'Blue Lagoon 300мл',
          price: "450р.",
        },
        {
          name: 'Pinot Colada 300мл',
          price: "450р.",
        },
        {
          name: 'Глинтвейн 200мл',
          price: "420р.",
        },
      ]
    },
    {
      name: 'Шоты',
      drink: [
        {
          name: 'Morena 60мл',
          price: "300р.",
        },
        {
          name: 'Red Dog 60мл',
          price: "300р.",
        },
        {
          name: 'B-52 60мл',
          price: "300р.",
        },
      ]
    },
  ],
};