import React, { Component } from "react";
import "./Menu.scss";
import { Link } from "react-router-dom";
import { links } from "../../data/main.json";

export default class Menu extends Component {
  constructor() {
    super();
    this.state = {
      menuState: 0,
      width: window.innerWidth,
      menuIsShown: false
    };
  }

  handleClick = e => {
    const menuState = e.target.dataset.state;
    const menuIsShown = false;
    menuState &&
      this.setState({
        menuState,
        menuIsShown
      });
  };

  updateDimensions = e => {
    const width = e.target.innerWidth;
    if (width => 1100) {
      this.setState({ menuIsShown: false });
    }
    this.setState({ width: e.target.innerWidth });
  };

  componentDidMount() {
    window.addEventListener("resize", this.updateDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions);
  }

  handleMenu = () => {
    this.setState({ menuIsShown: !this.state.menuIsShown });
  };

  render() {
    const { menuState, width, menuIsShown } = this.state;
    return (
      <section
        className="menu"
        onClick={this.handleClick}
        data-menustate={menuState}
      >
        {width < 1100 && <div className="burger" onClick={this.handleMenu} />}
        {menuIsShown && <div className="layout" onClick={this.handleMenu} />}
        {(width > 1100 || menuIsShown) && (
          <div className={`main-menu ${menuIsShown ? "hide_menu_active" : ""}`}>
            <Link to="/">
              <div className="logo" data-state="0" />
            </Link>
            <nav>
              <ul className="navigation">
                {links.map((el, i) => (
                  <Link key={i} to={el.route} data-state={i + 1}>
                    <li
                      data-state={i + 1}
                      className={`${menuState == i + 1 ? "li_active" : ""}`}
                    >
                      {el.name}
                    </li>
                  </Link>
                ))}
              </ul>
            </nav>
          </div>
        )}
      </section>
    );
  }
}
