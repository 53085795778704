import React, { Component } from "react";
import "./Background.scss";
import PhotoGallery from "../PhotoGallery/PhotoGallery";
import Actions from "../Actions/Actions";
import Rent from "../Rent/Rent";
import Events from "../Events/Events";
import Contacts from "../Contacts/Contacts";
import About from "../About/About";
import FoodMenu from "../FoodMenu/FoodMenu";
import { Route } from "react-router-dom";
import img_1 from "./img/img_1.jpg";
import img_1_bad from "./img/img_1_bad.jpg";
import img_2 from "./img/img_2.jpg";
import img_3 from "./img/img_3.jpg";

let IMAGES = [img_1_bad, img_1, img_2, img_3];

export default class Background extends Component {
  constructor() {
    super();
    this.state = {
      imageIndex: 0
    };
    document.addEventListener("DOMContentLoaded", this.changeImageWrapper);
  }

  changeImage = () => {
    if (this.state.imageIndex === IMAGES.length - 1) {
      this.setState({ imageIndex: 0 });
    } else {
      this.setState({ imageIndex: this.state.imageIndex + 1 });
    }
  };

  changeImageWrapper = () => {
    IMAGES.shift();
    console.log(IMAGES);
    setInterval(this.changeImage, 5000);
  };

  render() {
    const { routePath } = this.props;
    console.log(routePath);
    const { imageIndex } = this.state;
    return (
      <section
        className="background"
        style={{
          background: `url(${IMAGES[imageIndex]})`,
          transition: "all 1s"
        }}
      >
        <img className="img_plashka" src={img_1} width="1px" height="1px" />
        <img className="img_plashka" src={img_2} width="1px" height="1px" />
        <img className="img_plashka" src={img_2} width="1px" height="1px" />

        <div className={`border`}>
          <Route exact path="/" component={About} />
          <div
            className={`layout-white ${
              routePath.location.pathname === "/" ? "" : "layout-white_active"
            }`}
          >
            <Route exact path="/menu" component={FoodMenu} />
            <Route path="/gallery" component={PhotoGallery} />
            <Route path="/actions" component={Actions} />
            <Route path="/rent" component={Rent} />
            <Route path="/events" component={Events} />
            <Route path="/contacts" component={Contacts} />
          </div>
        </div>
      </section>
    );
  }
}
