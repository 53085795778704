import React from "react";
import ReactDOM from "react-dom";
import "./index.scss";
import * as serviceWorker from "./serviceWorker";
import { Router, withRouter } from "react-router-dom";
import { createHashHistory } from "history";
import Background from "./components/Background/Background";
import Menu from "./components/Menu/Menu";

const history = createHashHistory();

const BackgroundWithRouter = withRouter(props => <Background {...props} />);

ReactDOM.render(
  <Router history={history}>
    <div className="App container">
      <BackgroundWithRouter routePath={history} />
      <Menu />
    </div>
  </Router>,
  document.getElementById("root")
);

serviceWorker.unregister();
