import React, { Component } from "react";
import "./Events.scss";

export default class Events extends Component {
  render() {
    return (
      <section className="events">
        <div className="header">
          <p className="title">СОБЫТИЯ</p>
        </div>
        <div className="content-wrapper">
          <h2 className="text_title">Скоро здесь появятся наши события!</h2>
        </div>
      </section>
    );
  }
}
