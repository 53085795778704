import React, { Component } from "react";
import "./Rent.scss";
import { banquets } from "../../data/main.json";

export default class Rent extends Component {
  render() {
    return (
      <section className="rent">
        <div className="header">
          <p className="title">АРЕНДА</p>
        </div>
        <div className="content-wrapper">
          <div className="text_content">{banquets}</div>
          <div
            className="rent__image"
            style={{ backgroundImage: `url(./img/rent/rent.jpg)` }}
          />
        </div>
      </section>
    );
  }
}
