import React, { Component } from "react";
import "./Contacts.scss";
import { Map, Marker } from "yandex-map-react";
import { contacts } from "../../data/main.json";

export default class Contacts extends Component {
  render() {
    return (
      <section className="contacts">
        <div className="header">
          <p className="title">КОНТАКТЫ</p>
        </div>
        <div className="bottom">
          <div className="contact-info">
            <div className="info-container">
              <div className="text">
                <p dangerouslySetInnerHTML={{ __html: contacts.address }} />
                {contacts.phones.map((el, i) => (
                  <p key={i}>{el}</p>
                ))}
              </div>
              <div className="soc-icons">
                <a
                  target="_blank"
                  href="https://www.instagram.com/veranda_krasnogorsk/"
                  className="instagram"
                />
              </div>
            </div>
          </div>
          <div className="map-container">
            <div className="map">
              <Map
                onAPIAvailable={function() {
                  console.log("API loaded");
                }}
                width={"90%"}
                height={"380px"}
                center={[55.8255, 37.3321]}
                zoom={16}
              >
                <Marker lat={55.82555} lon={37.3321} />
              </Map>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
