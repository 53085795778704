import React, { Component } from "react";
import "./FoodMenu.scss";

import { FOOD_MENU } from './data';

export default class FoodMenu extends Component {
  constructor() {
    super();
    this.state = {
      menuState: "0"
    };
  }

  changeState = e => {
    const menuState = e.target.dataset.state;
    this.setState({ menuState });
  };

  render() {
    const { menuState } = this.state;
    return (
      <section className="foodMenu">
        <div className="header_foodMenu">
          <ul className="toggler" onClick={this.changeState}>
            <li
              data-state="0"
              className={`toggler_li ${
                menuState === "0" ? "active_toggle" : ""
              }`}
            >
              ОСНОВНОЕ МЕНЮ
            </li>
            <li
              data-state="1"
              className={`toggler_li ${
                menuState === "1" ? "active_toggle" : ""
              }`}
            >
              НАПИТКИ
            </li>
          </ul>
          <div
            className="underline"
            style={{ marginRight: menuState === "0" ? "175px" : "-255px" }}
          />
        </div>

        {menuState === "0" && (
          <div className="content_menu">
            <div className="content-wrapper">
              {FOOD_MENU.main.map((el, i) => (
                <div className="content-block" key={i}>
                  <p className="subtitle">{el.name}</p>
                  <div className="dishes">
                    {el.dishes.map((dish, k) => (
                      <div key={k} className="dish-name">
                        <span className="span-dish-name">{dish.name}</span>
                        <span className="span-dish-price">{dish.price}</span>
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}

        {menuState === "1" && (
          <div className="content_wine">
            <div className="content-wrapper">
              {FOOD_MENU.drinks.map((el, i) => (
                <div className="content-block" key={i}>
                  <p className="subtitle">{el.name}</p>
                  <div className="dishes">
                    {el.drink.map((drink, k) => (
                      <div key={k} className="dish-name">
                        <span className="span-dish-name">{drink.name}</span>
                        <span className="span-dish-price">{drink.price}</span>
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
      </section>
    );
  }
}
