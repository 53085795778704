import React, { Component } from "react";
import "./Actions.scss";
import { actions } from "../../data/main.json";

export default class Actions extends Component {
  render() {
    return (
      <section className="actions">
        <div className="header">
          <p className="title">АКЦИИ</p>
        </div>
        <div className="content-wrapper">
        <div className="action">
          <div className="action_text">
            <h2 className="text_title">Доставка</h2>
            <p className="text_content">
              Теперь, вы можете наслаждаться нашей кухней  не выходя из дома или офиса.<br/>
              Мы привезем ваш заказ, в любую точку Красногорска.<br/>
              Заказы принимаются с 12:00 да 22:30.<br/>
              Минимальная сумма заказа составляет 1000 руб.<br/>
              Телефон для доставки: <br/>
              <a href="tel:+74955637641"> +7 495 563 76 41</a>
            </p>
          </div>
        </div>        
        <div className="action">
          <div className="action_text">
            <h2 className="text_title">Караоке</h2>
            <p className="text_content">
              Каждую пятницу и субботу с 22.00 до 01.00 у Вас есть возможность исполнить свои любимые хиты.<br/>
              Депозит составляет 2000 руб. за одного человека.<br/>
              Просим вас предварительно связаться с менеджером для бронирования
            </p>
          </div>
        </div>
          {actions.map((el, i) => (
            <div className="action" key={i}>
              <div className="action_text">
                <h2 className="text_title">{el.title}</h2>
                <p className="text_content">{el.content}</p>
              </div>
              <div
                className="action_image"
                style={{
                  background: `url(./img/actions/${i + 1}.jpg)`,
                  backgroundSize: "cover"
                }}
              />
            </div>
          ))}
          <div className="action">
            <div className="action_text">
              <h2 className="text_title">Бонусы для постоянных клинетов</h2>
              <p className="text_content">
                Для постоянных гостей в нашем кафе предусмотрена бонусная
                программа. Всю информацию о картах, Вы можете получить у
                администратора или любого сотрудника
              </p>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
