import React, { Component } from "react";
import "./PhotoGallery.scss";
import Gallery from "react-grid-gallery";
import IMAGES from "./photos/index";

export default class PhotoGallery extends Component {
  render() {
    return (
      <section className="photo_gallery">
        <Gallery enableImageSelection={false} images={IMAGES} margin={10} />
      </section>
    );
  }
}
