import React from "react";
import "./About.scss";
import { about } from "../../data/main.json";

const About = () => (
  <section className="about">
    <div className="info">
      <p>{about}</p>
      <p>Теперь в нашем кафе доступен сервис доставки, а также караоке.<br/>Подробности в разделе Акции</p>
    </div>
  </section>
);

export default About;
